<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Socios - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="7"></b-col>
              <b-col sm="12" md="1">
                  <!-- <b-button type="button" title="Exportar Excel" @click="ExportExcel" class="form-control" variant="success"><i class="fas fa-file-excel"></i></b-button> -->
              </b-col>
              <b-col sm="6" md="3">
                <b-input-group>
                  <b-form-input @keyup.enter="ListPartner" type="search" v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListPartner">
                      <b-icon icon="search"></b-icon
                    ></b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col sm="6" md="1">
                <b-link v-if="Permission('PartnerAdd')"  class="btn form-control btn-primary" :to="{ path: '/socio/nuevo' }" append><i class="fas fa-file"></i></b-link>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="12%" class="text-center">Documento</th>
                    <th width="40%" class="text-center">Nombre</th>
                    <th width="17%" class="text-center">Celular</th>
                    <th width="10%" class="text-center">F. Registro</th>
                    <th width="8%" class="text-center">Estado</th>
                    <th width="8%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="item.id">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-left">{{ CodeDocumentType(item.document_type) +' - '+ item.document_number }}</td>
                    <td class="text-left"> {{ item.name }}</td>
                    <td class="text-left"> {{ item.cell_phone }}</td>
                    <td class="text-center"> {{ item.registration_date }}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 0" variant="danger">Inactivo</b-badge>
                      <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                      <b-badge v-if="item.state == 3" variant="warning">Fallecido</b-badge>
                      <b-badge v-if="item.state == 4" variant="info">Retirado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('PartnerEdit')" @click="EditPartner(item.id_partner)">Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PartnerView')" @click="ViewPartner(item.id_partner)">Ver</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PartnerView')" @click="ModalFamilyShow(item.id_partner)">Beneficiarios</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('AccumulatedContributionView')" @click="ViewContribution(item.id_partner)">Aportes</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('PartnerDelete')"  @click="ConfirmDeletePartner(item.id_partner)">Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListPartner" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
      <ModalFamily />
    </CRow>
  </div>
</template>
<style scoped>
.height-table{
    min-height: 250px !important;
}
</style>
<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
import ModalFamily from './components/ModalFamily';
import EventBus from "@/assets/js/EventBus";
export default {
  name: "UsuarioList",
  components:{
      ModalFamily,
  },
  data() {
    return {
      module:'Partner',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],
    };
  },
  mounted() {
    this.ListPartner();
  },
  methods: {
    ListPartner,
    EditPartner,
    ViewPartner,
    ConfirmDeletePartner,
    DeleteClient,
    Permission,
    CodeDocumentType,
    ExportExcel,
    ViewContribution,
    ModalFamilyShow,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};
function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}
function ModalFamilyShow(id_partner) {
  EventBus.$emit('ModalFamilyShow',id_partner);
}
function ListPartner() {
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "partner/list/" + search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: {token: this.token, module: this.module,role: 1,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

function EditPartner(id_partner) {
  this.$router.push({
    name: "PartnerEdit",
    params: { id_partner: je.encrypt(id_partner) },
  });
}

function ViewPartner(id_partner) {
  this.$router.push({
    name: "PartnerView",
    params: { id_partner: je.encrypt(id_partner) },
  });
}

function ViewContribution(id_partner) {
  this.$router.push({
    name: "AccumulatedContributionView",
    params: { id_partner: je.encrypt(id_partner) },
  });
}

function ExportExcel() {  
  let url = this.url_base + "excel-clients";
  window.open(url,'_blank');
}

function ConfirmDeletePartner(id_partner) {
  Swal.fire({
    title: "Esta seguro de eliminar el socio ?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteClient(id_partner);
    }
  });
}

function DeleteClient(id_partner) {
  let me = this;
  let url = this.url_base + "partner/delete/" + id_partner;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token,module: this.module,role: 4,},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_partner).indexOf(response.data.result.id_partner);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
