<template>
  <div>
    <b-modal size="xl" class="modal-size" hide-footer v-model="modal_family" title="FAMILIARES Y/O BENEFICARIOS">
      <b-row>
        <b-col md="12">
          <div class="table-responsive ">
            <table class="table table-hover table-bordered">
              <thead>
                <tr>
                  <th width="5%" class="text-center">#</th>
                  <th width="12%" class="text-center">Documento</th>
                  <th width="35%" class="text-center">Nombre</th>
                  <th width="10%" class="text-center">Celular</th>
                  <th width="10%" class="text-center">Tipo Beneficiario</th>
                  <th width="10%" class="text-center">Parentesco</th>
                  <th width="8%" class="text-center">Estado</th>
        
                </tr>
              </thead>
              <tbody v-for="(item, it) in partners_family" :key="it">
                <tr>
                  <td class="text-center">{{ it + 1 }}</td>
                  <td class="text-left">{{ CodeDocumentType(item.document_type) +' - '+ item.document_number }}</td>
                  <td class="text-left"> {{ item.name }}</td>
                  <td class="text-left"> {{ item.phone +" - "+item.cell_phone  }}</td>
                  <td class="text-left"> {{ NameTypeBeneficiary(item.type_beneficiary) }}</td>
                  <td class="text-left"> {{ NameRelationship(item.relationship) }}</td>
                  <td class="text-center">
                    <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                    <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </b-col>
      </b-row>
    </b-modal>

 
  </div>
</template>

<script>
import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";

const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../../pages/Loading';
import EventBus from "@/assets/js/EventBus";
export default {
  name: "UsuarioList",
  components:{
      LoadingComponent,
  },
  data() {
    return {
      module:'Partner',
      modal_family: false,
      role:5,
      id_partner: '',
      partners_family: [],
    };
  },
  mounted() {
    EventBus.$on('ModalFamilyShow', (id_partner) => {
      this.modal_family = true;
      this.id_partner = id_partner;
      this.ListPartner();
    });
    
  },
  methods: {
    ListPartner,
    CodeDocumentType,
    NameRelationship,
    NameTypeBeneficiary,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};


function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}

function NameTypeBeneficiary(code) {
  let name = "";
  switch (code) {
    case "1": name = "Titular Directo"; break;
    case "2": name = "Familiar"; break;
  }
  return name;
}

function NameRelationship(code) {
  let name = "";
  switch (code) {
    case "1": name = "ESPOSO (A)"; break;
    case "2": name = "HIJO (A)"; break;
    case "3": name = "PADRE"; break;
    case "4": name = "MADRE"; break;
    case "5": name = "TIO (A)"; break;
    case "6": name = "SOBRINO (A)"; break;
    case "7": name = "ABUELO (A)"; break;
    case "8": name = "HERMANO (A)"; break;
    case "9": name = "CONYUGE"; break;
    case "10": name = "PRIMO (A)"; break;
    case "11": name = "OTROS"; break;
  }
  return name;
}

function ListPartner() {
  let me = this;
  let url = me.url_base + "partner/view/"+this.id_partner;
  let data = me.partner;
  me.isLoading = true;
  axios({
    method: "get",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.partners_family = response.data.result.partners_family;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = false;
    });
}

</script>
